import type { IDoctor } from '~/EastclinicVueApi';

export const useDoctorsStore = defineStore('doctorsStore', () => {
  const doctorsCacheMap = ref<Map<number, IDoctor>>(new Map());

  const count = ref(0);
  const mainGroupCount = ref(0);
  const doctorInfoEl = ref(null);
  const doctorReviewsCount = ref(0);
  const lastGroup = ref('');

  const doctorInfoIsVisible = useElementVisibility(doctorInfoEl);

  const setCount = (countDoctors:number) => {
    count.value = countDoctors;
  };

  return {
    doctorsCacheMap,
    count,
    doctorInfoEl,
    doctorInfoIsVisible,
    doctorReviewsCount,
    lastGroup,
    mainGroupCount,
    setCount
  };
});
