<script setup lang="ts">

  // это обертка для капчи
  // в ней много логики, поэтому вынес отдельно
  // коллбек, который передается сюда это только часть колбека от капчи
  import YandexCaptcha from '~/UI/Yandex/Captcha.vue';
  const props = defineProps({
    // не путать с callback от капчи
    callback: {
      type: Function,
      required: true
    }
  });
  // const loading = defineModel();
  const captchaToken = ref<string>('');
  const captchaVisible = ref<boolean>(false);
  const error = ref(false);

  const captchaSubmit = async () => {
    captchaVisible.value = false;

    // // неочевидная логика колбеков (здесь возможно уже запускали smartCaptcha.execute())
    // // чтобы было логичнее надо бы завернуть в Promise стандартный callback (чатгпт в помощь)
    if (captchaToken.value !== '') {
      // если пользователь нажал submit, у нас уже есть токен
      await props.callback();
    } else if (error) {
      // если капча сломалась, делаем запись без неё ¯\_(ツ)_/¯
      await props.callback();
    } else {
      // зайдет сюда в первое нажатие submit
      // и props.callback запустит из captchaCallback
      // приходится так делать, потому что props.callback не может
      // дождаться smartCaptcha.execute() (нет Promise, о котором выше)
      window.smartCaptcha.execute();
    }
  };
  const captchaCallback = async (t:string) => {
    // так как капча подтверждается один раз
    // то и этот коллбек выполняется всего один раз
    captchaToken.value = t;
    if (captchaToken.value !== '') {
      if (!captchaVisible.value) {
        await props.callback();
      }
    } else {
      // где-то надо поймать момент, если пользователь
      // закроет окно капчи
      captchaVisible.value = true;
    }
  };

  defineExpose({
    captchaSubmit
  });

</script>

<template>
  <div>
    <YandexCaptcha
      :callback="captchaCallback"
      :visible="captchaVisible"
      @error="error = true"
    />
  </div>
</template>
