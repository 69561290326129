<script setup lang="ts">
  import { usePatientStore } from '~/EastclinicVueApi';

  const statePatient = usePatientStore();

  const setFio = (e) => {
    statePatient.setFio(e.target.value);
    e.target.value = statePatient.patientData.fio;
  };
</script>

<template>
  <div class="booking__dialog__item">
    <div class="booking__dialog__card" :class="{'error-border':!!(statePatient.fioError.value)}">
      <input
        id="fio"
        type="text"
        name="fio"
        :value="statePatient.patientData.fio"
        placeholder="Ваше имя и фамилия"
        @input="setFio"
        @blur="statePatient.checkFio()"
      >
    </div>
    <span v-if="statePatient.fioError.value" class="input-error-message">{{ statePatient.fioError.value }}</span>
  </div>
</template>
