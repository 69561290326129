import useCalltouch from '~/composables/useCalltouch';
import { BookingService, usePatientStore } from '~/EastclinicVueApi';
import type { IBookingRequest, IDoctor, ClinicInterface } from '~/EastclinicVueApi';
import type BookingFormViewProps from '~/components/Booking/interfaces/BookingFormViewProprs';
import type { TCartServiceItem } from '~/composables/useCartServices';

export const useBookingStore = defineStore('BookingStore', () => {
  const bookingFormViewProps: Ref<BookingFormViewProps | object> = ref({});
  const bookingService = new BookingService();
  const Patient = usePatientStore();

  const errorText = ref<string>('');
  const showModalBooking = ref<boolean>(false);
  const showLeaveMessage = ref<boolean>(false);
  const showBookingScheduleBlock = ref<boolean>(false);
  const showBookingSuccessMessage = ref<boolean>(false);
  const isHelpMessageSendedSuccess = ref<boolean>(false);
  const agreeCheckbox = ref<boolean>(false);
  const showHelpModal = ref<boolean>(false);
  const doctorCardState = ref();
  const slot = ref(0);
  const doctor = ref<IDoctor|null>(null);
  const clinic = ref<ClinicInterface|null>(null);
  const services = ref<TCartServiceItem[]|null>(null);
  const captcha = ref<string|null>(null);
  const bookingOnlyMessage = ref<boolean>(false);
  const loading = ref<boolean>(false);
  // const isUpdateSchedule = ref(false);

  const setErrorText = (error: string) => {
    errorText.value = error;
  };

  const setShowModalBooking = (show: boolean) => {
    showModalBooking.value = show;
    if (!show && !showLeaveMessage.value) {
      showLeaveMessage.value = true;
    }
    showBookingScheduleBlock.value = false;
  };
  const closeAll = () => {
    showModalBooking.value = showBookingSuccessMessage.value = showLeaveMessage.value = false;
  };

  const setBookingData = (data:{doctorId:number, timeSlot?:number}) => {
    const doctorStore = useDoctorsStore();
    if (!data.doctorId) { throw new Error('Not set doctor'); }
    doctor.value = doctorStore.doctorsCacheMap.get(data.doctorId) as IDoctor;
    if (data.timeSlot) { slot.value = data.timeSlot; }

    // clinic.value = doctorStore.getSelectedClinic(data.doctorId);
  };

  const setShowLeaveMessage = (show: boolean) => {
    showLeaveMessage.value = show;
  };

  const setShowBookingScheduleBlock = (show: boolean) => {
    showBookingScheduleBlock.value = show;
  };

  const setShowBookingSuccessMessage = (show: boolean) => {
    showBookingSuccessMessage.value = show;
  };

  const toggleBookingSuccessMessage = (show: boolean): void => {
    showBookingSuccessMessage.value = show;
  };

  const setBookingFormBlocks = (viewProps: BookingFormViewProps): void => {
    bookingFormViewProps.value = viewProps;
  };
  const checkAgreeCheckbox = (): boolean => {
    if (!agreeCheckbox.value) {
      setErrorText('Пожалуйста подтвердите что ознакомлены с условиями');
    } else {
      setErrorText('');
    }
    return agreeCheckbox.value;
  };
  const validate = () => {
    // console.log('Patient.checkFio()', Patient.checkFio());
    // console.log('Patient.checkPhone()', Patient.checkPhone());
    // console.log('checkAgreeCheckbox()', checkAgreeCheckbox());
    // console.log('validate', (Patient.checkFio() && Patient.checkPhone() && checkAgreeCheckbox()));
    return (Patient.checkFio() && Patient.checkPhone() && checkAgreeCheckbox());
  };

  const bookShort = async (isMarkdownForm?: boolean): Promise<IBookingRequest | undefined> => {
    bookingOnlyMessage.value = true;
    try {
      const bookingService = new BookingService();
      const res = await bookingService.book({
        patient: Patient.patientData,
        sessionId: usePageInfoStore().sessionId as string,
        // type: formType,
        type: '',
        captcha: captcha.value,
        onlyMessages: bookingOnlyMessage.value
      });

      agreeCheckbox.value = false;
      // const res = { ok: true };
      if (res?.ok) {
        isHelpMessageSendedSuccess.value = true;
        const { onLoaded } = useYandexMetrika();
        onLoaded((ym) => {
          if (isMarkdownForm) {
            ym.reachGoal('booking_down_done');
          } else {
            ym.reachGoal('callback_done');
          }
        });
        useCalltouch().forPatient(Patient.patientData).booking();
      } else {
        setErrorText(res?.error || 'Ошибка сервера, попробуйте позже');
      }
      loading.value = false;
      return res;
    } catch (e) {
      console.error('res empty', e);
      loading.value = false;
      setErrorText('Ошибка сервера, попробуйте позже');
    }
  };
  const book = async (): Promise<IBookingRequest | undefined> => {
    try {
      const bookingService = new BookingService();
      // const res = undefined;
      const res = await bookingService.book({
        patient: Patient.patientData,
        slot: slot.value,
        clinic: clinic.value,
        doctor: doctor.value,
        sessionId: usePageInfoStore().sessionId as string,
        // type: formType,
        captcha: captcha.value,
        onlyMessages: bookingOnlyMessage.value
      });
      // const res = { ok: true };
      agreeCheckbox.value = false;
      if (res?.ok) {
        showLeaveMessage.value = false;
        showBookingSuccessMessage.value = true;
        showModalBooking.value = false;
        const { onLoaded } = useYandexMetrika();
        onLoaded((ym) => {
          ym.reachGoal('booking_done');
        });
        useCalltouch().forPatient(Patient.patientData).booking();
        useDevScheduleStore().deleteSlot();
      } else {
        setErrorText(res?.error || 'Ошибка сервера, попробуйте позже');
      }
      bookingOnlyMessage.value = false;
      loading.value = false;
      return res;
    } catch (e) {
      loading.value = false;
      setErrorText('Ошибка сервера, попробуйте позже');
    }
  };

  const openHelpModal = () => {
    showHelpModal.value = true;
    const { onLoaded } = useYandexMetrika();
    onLoaded((ym) => {
      ym.reachGoal('callback_open');
    });
  };

  return {
    validate,
    bookingOnlyMessage,
    bookingFormViewProps,
    bookingService,
    Patient,
    slot,
    doctor,
    clinic,
    services,
    errorText,
    captcha,
    showModalBooking,
    showLeaveMessage,
    showBookingScheduleBlock,
    showBookingSuccessMessage,
    closeAll,
    isHelpMessageSendedSuccess,
    agreeCheckbox,
    showHelpModal,
    openHelpModal,
    checkAgreeCheckbox,
    setBookingData,
    setErrorText,
    setShowModalBooking,
    setShowLeaveMessage,
    setShowBookingScheduleBlock,
    setShowBookingSuccessMessage,
    toggleBookingSuccessMessage,
    setBookingFormBlocks,
    loading,
    // temporary

    doctorCardState,
    bookShort,
    book
  };
});
