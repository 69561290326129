import type { ClinicInterface } from '~/EastclinicVueApi';

// TODO: ВЫНЕСТИ ОТСЮДА ТИПЫ
export interface Slot {
  time: string
  timestamp: number
}

export interface Day {
  day: number
  weekday: string
  month: string
  slots: Slot[]
}

export interface Schedule {
  clinicId: number // брать объект из стора клиник
  days: Day[] // если пустой, то всё занято
}
export interface DoctorSchedules {
  doctorId: number
  schedule: Schedule[]
}

export interface DeletedSlot {
  doctorId: number
  clinicId: number
  day: number
  timestamp: number
}

export const useDevScheduleStore = defineStore('devScheduleStore', () => {
  const schedulesByDoctorId = ref<Map<number, Schedule[]>>(new Map());

  // TODO: придумать что-то более нормальное
  const nearestSlot = ref<number|null>(null);
  const clinicForNearestSlot = ref<ClinicInterface|null>(null);
  const dayForNearestSlot = ref<number|null>(null);

  const maybeDeletedSlot = ref<DeletedSlot|null>(null);

  const createSchedules = (schedules: DoctorSchedules[]) => {
    for (const schedule of schedules) {
      schedulesByDoctorId.value.set(schedule.doctorId, schedule.schedule);
    }
  };

  const cleanSchedules = () => {
    schedulesByDoctorId.value.clear();
  };

  const deleteSlot = () => {
    const slot = maybeDeletedSlot.value;
    if (!slot) {
      return;
    }
    const schedules = schedulesByDoctorId.value.get(slot.doctorId);
    if (!schedules) {
      return;
    }
    const scheduleIndex = schedules.findIndex((s) => s.clinicId === slot.clinicId);
    if (scheduleIndex === -1) {
      return;
    }
    const dayIndex = schedules[scheduleIndex].days.findIndex((d) => d.day === slot.day);
    if (dayIndex === -1) {
      return;
    }
    schedules[scheduleIndex].days[dayIndex].slots = schedules[scheduleIndex].days[dayIndex].slots.filter((s) => s.timestamp !== slot.timestamp);
  };

  const deleteNearestSlot = () => {
    nearestSlot.value = null;
    clinicForNearestSlot.value = null;
    dayForNearestSlot.value = null;
  };

  return {
    schedulesByDoctorId,
    nearestSlot,
    clinicForNearestSlot,
    dayForNearestSlot,
    maybeDeletedSlot,
    createSchedules,
    cleanSchedules,
    deleteSlot,
    deleteNearestSlot
  };
});
